@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");
@import url("https://fonts.googleapis.com/css?family=Alfa+Slab+One|Cinzel|Cousine|Homemade+Apple|Marck+Script|Maven+Pro|Poiret+One|Unica+One");

/* DESKTOP SCREENS */
@media all and (min-width: 900px) {
  .column-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 40vh;
    animation-duration: 1.9s;
    z-index: 1000;
  }

  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* :::::::::::::::::::::::::: INNER BOXES :::::::::::::::::::::::::::: */
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  .section {
    color: white;
    font-size: 32px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 80%;
    height: 70%;
    margin: 30px 30px;
    cursor: pointer;
    user-select: none;
    border-radius: 6px;
    box-shadow: 3px 3px 35px 2px rgba(247, 15, 15, 0.253);
    transition: all 1.3s ease;
    overflow: hidden;
    border-radius: 10px;
    -webkit-border-radius: 12px;
  }
  .section:hover {
    transition: all 0.7s ease;
    background: rgba(6, 12, 70, 0.455);
    text-shadow: 0px 0px 6px;
    box-shadow: 0.5px 0.23px 50px 6px rgba(0, 81, 255, 0.411);
  }
  .section:active {
    box-shadow: 6px 8px 20px 10px rgba(228, 181, 29, 0.486);
  }
  /* :::::::::::::::: Background Inner Boxes ::::::::::::::::::::: */
  .image-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .resume-img {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    filter: blur(2px);
    opacity: 1;
    transform: translate(0%, 0%);
    z-index: -1000;
  }
  .bio-img {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    filter: blur(2px);
    opacity: 1;
    transform: translate(0%, 0%);
    z-index: -1000;
  }

  p {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* PHONE SCREENS */
@media all and (max-width: 900px) {
  .column-container {
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60%;
    animation-duration: 1.9s;
    z-index: 1000;
  }

  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* :::::::::::::::::::::::::: INNER BOXES :::::::::::::::::::::::::::: */
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  .section {
    color: white;
    font-size: 32px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 90%;
    height: 130px;
    margin: 10px 10px;
    cursor: pointer;
    user-select: none;
    border-radius: 6px;
    box-shadow: 3px 3px 5px 2px rgba(90, 5, 5, 0.253);
    transition: all 1.3s ease;
    overflow: hidden;
    border-radius: 10px;
    -webkit-border-radius: 12px;
  }
  .section:hover {
    transition: all 0.7s ease;
    background: rgba(6, 12, 70, 0.455);
    text-shadow: 0px 0px 6px;
    box-shadow: 0.5px 0.23px 6px 6px rgba(0, 81, 255, 0.411);
  }
  .section:active {
    box-shadow: 6px 8px 4px 10px rgba(228, 181, 29, 0.486);
  }
  /* :::::::::::::::: Background Inner Boxes ::::::::::::::::::::: */
  .image-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    overflow: hidden;
  }
  .resume-img {
    position: relative;
    width: 100%;
    bottom: 0;
    left: 0;
    filter: blur(2px);
    opacity: 1;
    transform: translate(0%, 0%);
    z-index: -1000;
  }
  .bio-img {
    position: relative;
    width: 100%;
    bottom: 0;
    left: 0;
    filter: blur(2px);
    opacity: 1;
    transform: translate(0%, 0%);
    z-index: -1000;
  }

  p {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
