.program-containerD {
  display: flex;
  padding: 10px;
  border: 2px solid rgba(0, 0, 0, 0.142);
  border-top: none;
  border-radius: 6px;
  text-align: center;
}

.outer-program {
  float: left;
  width: 100%;
}

.prog-item {
  margin: auto;
  font-size: 15px;
  font-weight: bold;
}

.program-containerD:hover {
  background: rgba(116, 74, 196, 0.108);
}

@media screen and (max-width: 900px) {
  .program-containerD {
    display: flex;
    padding: 10px;
    border: 2px solid rgba(0, 0, 0, 0.142);
    border-top: none;
    border-radius: 6px;
    text-align: center;
  }

  .outer-program {
    float: left;
    width: 100%;
  }

  .prog-item {
    margin: auto;
    font-size: 16px;
    font-weight: bold;
  }

  .program-containerD:hover {
    background: rgba(116, 74, 196, 0.108);
  }
}
