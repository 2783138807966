@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");
@import url("https://fonts.googleapis.com/css?family=Alfa+Slab+One|Cinzel|Cousine|Homemade+Apple|Marck+Script|Maven+Pro|Poiret+One|Unica+One");

/* DESKTOP SCREENS */
@media all and (min-width: 900px) {
  .column-container-move {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 90%;
    height: 10%;
    animation-duration: 1.9s;
    transition: all 2s ease;
  }
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* :::::::::::::::::::::::::: INNER BOXES :::::::::::::::::::::::::::: */
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

  .section-move {
    font-size: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 80%;
    height: 4vh;
    margin: 5px;
    cursor: pointer;
    user-select: none;
    border-radius: 6px;
    box-shadow: 3px 3px 35px 2px rgba(247, 15, 15, 0.253);
    transition: all 1.3s ease;
    animation-duration: 2.3s;
    overflow: hidden;
    z-index: 1000;
  }
  p {
    margin: 0;
  }
  .section-move:hover {
    transition: all 0.7s ease;
    background: rgba(6, 12, 70, 0.178);
    box-shadow: 0.5px 0.23px 50px 6px rgba(0, 81, 255, 0.411);
    z-index: 1000;
  }
  .section-move:active {
    box-shadow: 6px 8px 20px 10px rgba(228, 181, 29, 0.486);
  }

  .inner-sec {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ole-container {
    margin: auto;
    min-height: 100%;
    /* position: relative; */
    /* display: flex; */
    width: 100%;
    transition: all 2s ease;
  }

  .x-button {
    display: block;
    margin: auto;
    margin-right: 50px;
    margin-top: 30px;
    color: rgb(46, 41, 41);
    font-size: 38px;
    height: 40px;
    width: 40px;
    text-align: right;
  }

  .x-button:hover {
    color: rgb(87, 39, 109);
    cursor: pointer;
  }

  .active {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: 4px solid rgb(69, 51, 228);
    box-shadow: 2px 0 10px #9ecaed;
    /* box-shadow: 0px 5px blue; */
  }
}

/* PHONE SCREENS */
@media all and (max-width: 900px) {
  .column-container-move {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 10%;
    animation-duration: 1.9s;
    transition: all 2s ease;
  }
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* :::::::::::::::::::::::::: INNER BOXES :::::::::::::::::::::::::::: */
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

  .section-move {
    font-size: 18px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    height: 6vh;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    border-radius: 6px;
    box-shadow: 1px 1px 3px 1px rgba(247, 15, 15, 0.253);
    transition: all 1.3s ease;
    animation-duration: 2.3s;
    overflow: hidden;
    z-index: 1000;
  }
  p {
    margin: 0;
  }
  .section-move:hover {
    transition: all 0.7s ease;
    background: rgba(6, 12, 70, 0.078);
    box-shadow: 0.5px 0.23px 3px 2px rgba(0, 81, 255, 0.211);
    z-index: 1000;
  }
  .section-move:active {
    box-shadow: 3px 2px 1px 10px rgba(228, 181, 29, 0.286);
  }

  .inner-sec {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ole-container {
    margin: auto;
    min-height: 100%;
    position: relative;
    /* display: flex; */
    width: 100%;
    transition: all 2s ease;
  }

  .x-div {
    display: flex;
    width: 99%;
    margin-bottom: 1.5%;
  }
  i {
    margin: 0;
  }

  .x-button {
    display: flex;
    margin: auto;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: rgb(46, 41, 41);
    font-size: 38px;
    height: 20px;
    width: 40px;
    text-align: right;
  }

  .x-button:hover {
    color: rgb(87, 39, 109);
    cursor: pointer;
  }

  .active {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: 4px solid rgb(69, 51, 228);
    box-shadow: 2px 0 10px #9ecaed;
    /* box-shadow: 0px 5px blue; */
  }
}
