.skill-key {
  font-size: 10px;
  margin: 5px;
  margin-left: 0;
  margin-right: 0;
}
.skill-key i {
  font-size: 6px;
}

.skillRow {
  float: left;
  width: 25%;
}

.skillItem {
  content: "";
  display: table;
  clear: both;
  margin: 2px;
  border: 1px dotted rgba(0, 0, 0, 0.115);
  border-radius: 3px;
  width: 100%;
  user-select: none;
}

@media screen and (max-width: 900px) {
  .skillItem {
    font-size: 10px;
  }
}
