@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");
@import url("https://fonts.googleapis.com/css?family=Alfa+Slab+One|Cinzel|Cousine|Homemade+Apple|Marck+Script|Maven+Pro|Poiret+One|Unica+One");

/* DESKTOP SCREENS */
@media all and (min-width: 900px) {
  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

  .social-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8vh;
    background-image: linear-gradient(
      to bottom,
      rgba(223, 191, 132, 0.132),
      rgba(77, 255, 187, 0.147),
      rgba(77, 133, 255, 0)
    );
    transition: all 2s ease;
    margin: auto;
  }

  .social-start {
    opacity: 0;
  }
  .social-end {
    opacity: 1;
  }

  .social-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    color: black;
    margin: 0px 5%;
    cursor: pointer;
    opacity: 0.7;
  }

  a:hover {
    text-decoration: none;
  }

  .social-item:hover {
    opacity: 1;
    color: rgb(52, 29, 156);
  }

  .social-item:active {
    color: rgba(150, 40, 40, 0.747);
  }

  .btn-explain {
    position: absolute;
    opacity: 0;
    color: black;
    font-weight: bold;
    font-size: 0px;
    margin-top: 0px;
    transition: all 0.5s ease;
  }

  .social-item:hover + .btn-explain {
    position: absolute;
    transition: all 0.5s ease;
    opacity: 1;
    font-size: 9px;
    margin-top: 4px;
    text-align: center;
    /* transform: translate(-2px, 0px); */
  }

  .linker {
    text-decoration: none;
    margin: 0px 5%;
  }
  .twit {
    height: 20%;
    z-index: 2;
    opacity: 1;
  }

  .social-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    color: black;
    margin: 0px 5%;
    cursor: pointer;
    opacity: 0.7;
  }
}

/* PHONE SCREENS */
@media all and (max-width: 900px) {
  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

  .social-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8vh;
    min-height: 80px;
    width: 80%;
    background-image: linear-gradient(
      to bottom,
      rgba(223, 191, 132, 0.132),
      rgba(77, 255, 187, 0.147),
      rgba(77, 133, 255, 0)
    );
    transition: all 2s ease;
    margin: auto;
  }

  .social-start {
    opacity: 0;
  }
  .social-end {
    opacity: 1;
  }

  .social-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    color: black;
    margin: 0px 5%;
    cursor: pointer;
    opacity: 0.7;
  }

  a:hover {
    text-decoration: none;
  }

  .social-item:hover {
    opacity: 1;
    color: rgb(52, 29, 156);
  }

  .social-item:active {
    color: rgba(150, 40, 40, 0.747);
  }
  .linker {
    text-decoration: none;
    margin: 0px 5%;
  }
  .twit {
    height: 20%;
    z-index: 2;
    opacity: 1;
  }

  .social-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    color: black;
    margin: 0px 5%;
    cursor: pointer;
    opacity: 0.7;
  }

  .btn-explain {
    position: absolute;
    opacity: 0;
    color: black;
    font-weight: bold;
    font-size: 0px;
    margin-top: 0px;
    transition: all 0.5s ease;
  }
}
