@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");
@import url("https://fonts.googleapis.com/css?family=Alfa+Slab+One|Cinzel|Cousine|Homemade+Apple|Marck+Script|Maven+Pro|Poiret+One|Unica+One");

/* DESKTOP SCREENS */
@media all and (min-width: 900px) {
  /* :::::::::::::::::: Final MIDDLE SECTION ::::::::::::::::::: */
  .main-container {
    max-width: 1400px;
    margin: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 58vh;
    /* min-height: 400px; */
    opacity: 1;
    transition: all 2s ease-in-out;
  }
  .main-box-start {
    opacity: 0;
    transition: opacity 1.3s ease-in-out;
  }
  .main-box-end {
    opacity: 1;
    transition: opacity 1.3s, transform 1.2s ease-in-out;
  }
  .main-box-hide {
    opacity: 0;
    height: 0px;
    transition: opacity 1.2s, height 1.2s, ease-in-out;
    z-index: -100;
    overflow: hidden;
  }

  .bar-box-start {
    opacity: 0;
    height: 0px;
    display: none;
    transition: opacity 0.9s, height 1.2s ease-in-out;
  }

  .bar-box-move {
    opacity: 0;
    height: 0px;
    display: hidden;
    transition: opacity 1.2s, height 1.2s ease-in-out;
  }
  .bar-box-end {
    opacity: 1;
    transition: opacity 1.2s, height 1.2s ease-in-out;
    height: 100%;
    display: flex;
  }
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* :::::::::::::::::: CENTER BOX OF MIDDLE SECTION ::::::::::::::::::: */
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  .inner-container {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 90%;
    border-radius: 30px;
    animation-duration: 0.7s;
    transition: all 2s ease;
  }

  .new-bar {
    max-width: 1400px;
    margin: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 58vh;
    transition: all 2s ease;
  }

  .new-bar.enter-done {
    display: flex;
    height: 56.5vh;
    margin: auto;
    padding: 10px 0 0 0;
    transition: all 1s ease;
  }

  .new-bar.enter-done {
    display: flex;
    height: 100.5vh;
    margin: auto;
    padding: 10px 0 0 0;
    transition: all 1s ease;
  }

  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    margin: auto; /* or absolute */
    z-index: 1000;
    color: blue;
    width: 40%;
    height: 80px;
    top: 50%;
    left: 30%;
  }
}

/* PHONE SCREENS */
@media all and (max-width: 900px) {
  /* :::::::::::::::::: Final MIDDLE SECTION ::::::::::::::::::: */
  .main-container {
    max-width: 1400px;
    margin: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    /* min-height: 400px; */
    opacity: 1;
    transition: all 2s ease-in-out;
  }
  .main-box-start {
    opacity: 0;
    transition: opacity 1.3s ease-in-out;
  }
  .main-box-end {
    opacity: 1;
    transition: opacity 1.3s, transform 1.2s ease-in-out;
  }
  .main-box-hide {
    opacity: 0;
    height: 0px;
    transition: opacity 1.2s, height 1.2s ease-in-out;
    z-index: -100;
  }

  .bar-box-start {
    opacity: 0;
    height: 0px;
    display: none;
    transition: opacity 0.9s, height 1.2s ease-in-out;
  }

  .bar-box-move {
    opacity: 0;
    height: 0px;
    display: hidden;
    transition: opacity 1.2s, height 1.2s ease-in-out;
  }
  .bar-box-end {
    opacity: 1;
    transition: opacity 1.2s, height 1.2s ease-in-out;
    height: 100%;
    display: flex;
  }
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* :::::::::::::::::: CENTER BOX OF MIDDLE SECTION ::::::::::::::::::: */
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  .inner-container {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 90%;
    border-radius: 30px;
    animation-duration: 0.7s;
    transition: all 2s ease;
  }

  .new-bar {
    max-width: 1400px;
    margin: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 58vh;
    transition: all 2s ease;
  }

  .new-bar.enter-done {
    display: flex;
    height: 56.5vh;
    margin: auto;
    padding: 10px 0 0 0;
    transition: all 1s ease;
  }

  .new-bar.enter-done {
    display: flex;
    height: 100.5vh;
    margin: auto;
    padding: 10px 0 0 0;
    transition: all 1s ease;
  }

  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    margin: auto; /* or absolute */
    z-index: 1000;
    color: blue;
    width: 40%;
    height: 80px;
    top: 50%;
    left: 30%;
  }
}
