@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");
@import url("https://fonts.googleapis.com/css?family=Alfa+Slab+One|Cinzel|Cousine|Homemade+Apple|Marck+Script|Maven+Pro|Poiret+One|Unica+One");

/* DESKTOP SCREENS */
@media all and (min-width: 900px) {
  * {
    box-sizing: border-box;
  }
  .App {
    text-align: center;
    position: relative;
    min-height: 100vh;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    -webkit-transform: translate3d(0, 0, 0);
  }
  .above-footer-cont {
    min-height: 100;
    padding-bottom: 3vh;
  }

  ::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  .body-space {
    position: relative;
    width: 100%;
    min-height: 68vh;
  }
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

  /* .backup-fonts {
    font-family: "Maven Pro", sans-serif;
    font-family: "Cinzel", serif;
    font-family: "Alfa Slab One", cursive;
    font-family: "Poiret One", cursive;
    font-family: "Marck Script", cursive;
    font-family: "Unica One", cursive;
    font-family: "Homemade Apple", cursive;
    font-family: "Cousine", monospace;
  } */
}

@media all and (max-width: 900px) {
  * {
    box-sizing: border-box;
  }
  .App {
    text-align: center;
    position: relative;
    min-height: 100vh;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    -webkit-transform: translate3d(0, 0, 0);
  }
  .above-footer-cont {
    min-height: 100;
    padding-bottom: 3vh;
  }

  ::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  .body-space {
    position: relative;
    width: 100%;
    min-height: 68vh;
  }
}
