.card:hover {
  background-color: #20244948;

  box-shadow: 1 3px rgb(48, 21, 21);
}

.card:active {
  background-color: #303449;
  box-shadow: 1 3px rgb(48, 21, 21);
  transform: translateY(4px);
  outline: none;
}

/* For Phones & Pads */
@media all and (max-width: 900px) {
  .container-blog {
    height: 40%;
    margin-bottom: 20px;
    width: 90%;
    margin: auto;
  }
  .outer-card-blog {
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 100%;
    margin: auto;
  }

  .boxerson {
    overflow: hidden;
    float: left;
    height: 160px;
    width: 100%;
    margin: auto;
    margin: 1%;
    margin-top: 10px;
    border-radius: 20px;
  }

  .card {
    height: 100%;

    user-select: none;
    cursor: pointer;

    z-index: 15;
  }

  .container-image {
    height: 100%;
    min-width: 100%;

    position: absolute;
    z-index: -100;
    margin-top: 0%;
    opacity: 0.93;
    filter: brightness(78%);
  }
  .h1-card {
    font-size: 23px;
    font-weight: bold;
    margin: auto;
    padding: 0;
    position: relative;
    z-index: 2;
    color: white;
  }

  h2 {
    font-size: calc(10px + 1vmin);

    margin: auto;
    z-index: 5;
    user-select: none;
  }
  .p-card {
    position: absolute;
    width: 100%;
    bottom: 0;
    margin: auto;
    z-index: 2;
    text-align: center;
    color: rgb(245, 216, 216);
  }
}

/* For Desktops */
@media all and (min-width: 900px) {
  .container-blog {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40%;
    margin-bottom: 20px;
    width: 90%;
    margin: auto;
  }
  .outer-card-blog {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 100%;
    margin: auto;
  }

  .boxerson {
    overflow: hidden;
    float: left;
    height: 160px;
    width: 46%;
    margin: auto;
    margin: 1%;
    margin-top: 10px;
    border-radius: 20px;
  }

  .card {
    height: 100%;

    user-select: none;
    cursor: pointer;

    z-index: 15;
  }

  .container-image {
    width: 100%;

    position: absolute;
    z-index: -100;
    margin-top: -10%;
    opacity: 0.93;
    filter: brightness(78%);
  }
  .h1-card {
    font-size: 23px;
    font-weight: bold;
    margin: auto;
    padding: 0;
    position: relative;
    z-index: 2;
    color: white;
  }

  h2 {
    font-size: calc(10px + 1vmin);

    margin: auto;
    z-index: 5;
    user-select: none;
  }
  .p-card {
    position: absolute;
    width: 100%;
    bottom: 0;
    margin: auto;
    z-index: 2;
    text-align: center;
    color: rgb(245, 216, 216);
  }
}
