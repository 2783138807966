/* DESKTOP SCREENS */
@media all and (min-width: 900px) {
  .portfolio-cont {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    width: 96%;
    margin: auto;
    overflow: hidden;
  }

  .project-cont {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 44%;
    height: 210px;
    overflow: hidden;
    margin: 0% 2% 2% 2%;
    border-radius: 7px;
    border-top-right-radius: 1px;
    background: rgba(39, 36, 187, 0.227);
    box-shadow: 4px 4px 3px rgba(0, 0, 0, 0.562);
    transition: transform 0.3s, box-shadow 0.3s ease;
  }

  .project-cont:hover {
    /* transform: translate(1px, 2px); */
    transition: transform 0.3s, box-shadow 0.3s smooth;
    box-shadow: 4px 3px 2px rgba(0, 0, 0, 0.562);
    background: rgba(31, 31, 185, 0.307);
  }

  .portfolio-piccont {
    top: 0;
    height: 110px;
    position: absolute;
    font-size: 18px;
    margin: 0;
    width: 100%;
    object-fit: cover;
    margin-top: -30px;
  }

  .under-img {
    top: 80px;
    width: 100%;
    position: absolute;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
  }

  .port-title {
    border-bottom-left-radius: 50% 20%;
    border-bottom-right-radius: 50% 20%;

    font-size: 18px;
    margin: 0;
    width: 100%;
    background: rgba(9, 33, 65, 0.733);
  }

  .mid-card {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    color: rgb(15, 121, 207);
  }

  .in-cardL {
    text-shadow: 1px 2px 3px rgb(255, 255, 255);
    font-size: 14px;
    width: 40%;
  }
  .in-cardR {
    text-shadow: 1px 2px 3px rgb(255, 255, 255);
    font-size: 14px;
    margin: 0;
    width: 40%;
  }
  .info {
    font-weight: normal;
    font-size: 12px;
    color: rgba(34, 32, 32, 0.685);
    margin: 5px 0px 5px 0px;
    line-height: 12px;
  }

  .card-links {
    border: 1px solid black;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    background: rgba(248, 220, 96, 0.397);
    height: 40px;
    width: 38%;
    margin: 0px 18px;
    box-shadow: 2px 2px 1.5px rgba(0, 0, 0, 0.462);
    transition: transform 0.3s, box-shadow 0.3s ease;
  }

  .card-links:hover {
    box-shadow: 2px 2px 1.5px rgba(0, 0, 0, 0.462);
    background: rgba(41, 41, 168, 0.307);
    cursor: pointer;
  }

  .card-links:active {
    transform: translate(1px, 2px);
    transition: transform 0.3s, box-shadow 0.3s ease;
    box-shadow: 2px 2px 1.5px rgba(0, 0, 0, 0.462);
    background: rgba(31, 31, 185, 0.307);
    cursor: pointer;
  }
}

/* PHONE SCREENS */
@media all and (max-width: 900px) {
  .portfolio-cont {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    flex-direction: column;
    width: 96%;
    margin: auto;
    overflow: hidden;
  }

  .project-cont {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 180px;
    overflow: hidden;
    margin: 0% 2% 6% 2%;
    border-radius: 7px;
    border-top-right-radius: 1px;
    background: rgba(39, 36, 187, 0.227);
    box-shadow: 4px 4px 3px rgba(0, 0, 0, 0.562);
    transition: transform 0.3s, box-shadow 0.3s ease;
  }

  .project-cont:hover {
    /* transform: translate(1px, 2px); */
    transition: transform 0.3s, box-shadow 0.3s smooth;
    box-shadow: 4px 3px 2px rgba(0, 0, 0, 0.562);
    background: rgba(31, 31, 185, 0.307);
  }

  .portfolio-piccont {
    top: 0;
    height: 80px;
    position: absolute;
    font-size: 18px;
    margin: 0;
    width: 100%;
    object-fit: cover;
    margin-top: -30px;
  }

  .under-img {
    top: 50px;
    width: 100%;
    position: absolute;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
  }

  .port-title {
    border-bottom-left-radius: 50% 20%;
    border-bottom-right-radius: 50% 20%;

    font-size: 18px;
    margin: 0;
    width: 100%;
    background: rgba(9, 33, 65, 0.733);
  }

  .mid-card {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    color: rgb(15, 121, 207);
  }

  .in-cardL {
    text-shadow: 1px 2px 3px rgb(255, 255, 255);
    font-size: 14px;
    width: 48%;
  }
  .in-cardR {
    text-shadow: 1px 2px 3px rgb(255, 255, 255);
    font-size: 14px;
    margin: 0;
    width: 48%;
  }
  .info {
    font-weight: normal;
    font-size: 12px;
    color: rgba(34, 32, 32, 0.685);
    margin: 5px 0px 5px 0px;
    line-height: 12px;
  }

  .card-links {
    border: 1px solid black;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    background: rgba(248, 220, 96, 0.397);
    height: 40px;
    width: 38%;
    margin: 0px 18px;
    box-shadow: 2px 2px 1.5px rgba(0, 0, 0, 0.462);
    transition: transform 0.3s, box-shadow 0.3s ease;
  }

  .card-links:hover {
    box-shadow: 2px 2px 1.5px rgba(0, 0, 0, 0.462);
    background: rgba(41, 41, 168, 0.307);
    cursor: pointer;
  }

  .card-links:active {
    transform: translate(1px, 2px);
    transition: transform 0.3s, box-shadow 0.3s ease;
    box-shadow: 2px 2px 1.5px rgba(0, 0, 0, 0.462);
    background: rgba(31, 31, 185, 0.307);
    cursor: pointer;
  }
}
