@media all and (min-width: 900px) {
  .AppResume {
    width: 90%;
    max-width: 1400px;
    margin: auto;
  }

  .name-bnr {
    width: 100%;
    display: none;
    text-align: center;
    align-content: center;
    margin-top: 40px;
    margin-left: 0.5%;
    font-family: "Lato", sans-serif;
    color: rgb(46, 46, 46);
    font-size: 26px;
    height: 80px;
  }

  .job-bnr {
    font-family: "Lato", sans-serif;
    text-align: center;
    display: none;
    font-size: 20px;
    margin: auto;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    width: 100%;
    height: 70px;
    background-image: linear-gradient(
      to right,
      rgba(47, 101, 163, 0.38),
      rgb(61, 60, 134, 0.38),
      rgb(173, 72, 94, 0.38)
    );
  }
  .prof-pic {
    margin-top: 10px;
    height: 146px;
    border-radius: 100px;
  }

  .main-containerResume {
    text-align: center;
    display: grid;
    height: 100%;
    max-width: 1000px;
    margin: auto;
    grid-template-columns: 35% 65%;
  }
  .left-sidebar {
    float: left;
    left: 0;
    height: 80%;
  }
  .contact {
    grid-template-columns: 20% 70%;
    display: grid;
    grid-gap: 2%;
    height: 17.85%;
  }

  .cont-item {
    display: flex;
    justify-content: center;
    color: rgb(129, 85, 201);
    background: rgba(233, 230, 230, 0.514);
    font-weight: bold;
    align-items: center;
    font-size: 16px;
    text-decoration: none;
    border: 1px solid rgba(44, 44, 44, 0.159);
    border-radius: 10px;
    font-family: "Baloo Bhai", cursive;
    font-weight: normal;
    user-select: text;
  }

  .cont-item:hover {
    color: rgba(49, 49, 143, 0.705);
    cursor: pointer;
    background: rgba(184, 180, 180, 0.425);
  }

  .spacer {
    height: 14px;
    width: 99%;
  }
  .spacer2 {
    height: 4.5px;
    width: 99%;
  }

  .left-brdr {
    /* border: 1px solid rgba(0, 0, 0, 0.13);
    border-radius: 10px; */
    font-family: "Lato", sans-serif;
    font-size: 20px;
  }

  .skill-container {
    display: flex;
    padding-bottom: 10px;
    width: 100%;
    /* border: 2px solid rgba(0, 0, 0, 0.142);
    border-radius: 6px; */
    text-align: center;
  }

  .phone-skills {
    width: 90%;
    margin: auto;
  }

  .titles {
    text-align: center;
    display: flex;
    font-size: 28px;
    font-weight: bold;
    height: 50px;
    width: 100%;
    margin-bottom: 6px;
    align-items: center;
    justify-content: center;
    user-select: none;
  }
  .dev-title {
    font-family: lobster, cursive;
    color: rgba(36, 34, 34, 0.774);
  }

  .divider {
    width: 100%;
    height: 4px;
    background-image: linear-gradient(
      to right,
      rgba(47, 101, 163, 0.38),
      rgb(61, 60, 134, 0.38),
      rgb(173, 72, 94, 0.38)
    );
    border-radius: 6px;
  }

  .right-block {
    float: right;
    right: 0;
    height: 80%;
  }

  .right-grid {
    float: right;
    width: 95%;
    margin-right: 12px;
  }
  .pdf-btton {
    display: flex;
    width: 55%;
    padding: 2%;
    margin: auto;
    background: rgba(86, 79, 180, 0.315);
    border-radius: 10px;
    text-align: center;
  }

  .popup {
    position: absolute;
    width: 400px;
    height: 400px;
  }
}

/* :::::::::::::::::::::: PHONE VIEW ::::::::::::::::::::::::::: */
@media all and (max-width: 900px) {
  .AppResume {
    width: 90%;
    flex-direction: column;
    margin: auto;
    display: flex;
  }

  .name-bnr {
    width: 100%;
    display: none;
    justify-content: center;
    text-align: center;
    align-content: center;
    margin-top: 20px;
    font-family: "Lato", sans-serif;
    color: rgb(46, 46, 46);
    font-size: 26px;
    height: 60px;
  }

  .job-bnr {
    font-family: "Lato", sans-serif;
    text-align: center;
    display: none;
    font-size: 20px;
    margin: auto;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    width: 100%;
    height: 70px;
    background-image: linear-gradient(
      to right,
      rgba(47, 101, 163, 0.38),
      rgb(61, 60, 134, 0.38),
      rgb(173, 72, 94, 0.38)
    );
  }
  .prof-pic {
    margin-bottom: 10px;
    height: 146px;
    border-radius: 100px;
  }

  .main-containerResume {
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 90%;
  }
  .left-sidebar {
  }
  .contact {
    grid-template-columns: 15% 70%;
    display: grid;
    grid-gap: 1%;
    height: 22%;
    margin-bottom: 10px;
  }

  .cont-item {
    display: flex;
    justify-content: center;
    color: rgb(132, 78, 219);
    background: rgba(233, 230, 230, 0.514);
    font-weight: bold;
    align-items: center;
    font-size: 16px;
    text-decoration: none;
    border: 1px solid rgba(44, 44, 44, 0.159);
    border-radius: 10px;
    font-family: "Baloo Bhai", cursive;
    font-weight: normal;
    user-select: text;
  }

  .cont-item:hover {
    color: rgba(49, 49, 143, 0.705);
    cursor: pointer;
    background: rgba(184, 180, 180, 0.425);
  }

  .spacer {
    height: 14px;
    width: 99%;
  }
  .spacer2 {
    height: 4.5px;
    width: 99%;
  }

  .left-brdr {
    /* border: 1px solid rgba(0, 0, 0, 0.13);
    border-radius: 10px; */
    font-family: "Lato", sans-serif;
    font-size: 20px;
  }

  .skill-container {
    display: flex;
    padding-bottom: 10px;
    width: 100%;
    /* border: 2px solid rgba(0, 0, 0, 0.142);
    border-radius: 6px; */
    text-align: center;
  }

  .phone-skills {
    width: 90%;
    margin: auto;
  }

  .titles {
    text-align: center;
    display: flex;
    font-size: 28px;
    font-weight: bold;
    height: 50px;
    width: 100%;
    margin-bottom: 6px;
    align-items: center;
    justify-content: center;
    user-select: none;
  }
  .dev-title {
    font-family: lobster, cursive;
    color: rgba(36, 34, 34, 0.774);
  }

  .divider {
    width: 100%;
    height: 4px;
    background-image: linear-gradient(
      to right,
      rgba(47, 101, 163, 0.38),
      rgb(61, 60, 134, 0.38),
      rgb(173, 72, 94, 0.38)
    );
    border-radius: 6px;
  }

  .right-block {
    height: 80%;
  }

  .right-grid {
    float: right;
    width: 100%;
  }

  .pdf-btton {
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    padding: 2%;
    margin: auto;
    background: rgba(86, 79, 180, 0.315);
    border-radius: 10px;
    margin-bottom: 5px;
  }
}

@media print and (min-width: 200px) {
  .AppResume {
    background: white;
    width: 150%;
    transform: translateY(-1020px) translateX(-270px) scale(0.7);
    margin: auto;
    position: absolute;
    z-index: 1500;
  }
}
