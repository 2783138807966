.outer-personality {
  margin: auto;
  font-weight: bold;
}

.inner-personality {
  display: flex;
  float: left;
  width: 50%;
  height: 50%;
  text-align: center;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.inner-right-personality {
  display: flex;
  float: right;
  width: 70%;
  height: 50%;
  text-align: center;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.personality-item {
  border: 2px solid rgba(128, 128, 128, 0.342);
  border-radius: 10px;
  display: flex;
  min-height: 60px;
  margin: auto;
  margin-top: 5px;
  padding: 4px;
  font-weight: bold;
  font-size: 14px;
  font-size: calc(13px + 3 * ((100% - 750px) / 1000));
}

.personality-item:hover {
  background: rgba(116, 74, 196, 0.108);
}

@media screen and (max-width: 900px) {
  .personality-item {
    font-size: 11px;
  }
}
