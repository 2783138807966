@media all and (min-width: 900px) {
  /* :::::::::::: FIRST TITLE SECTION :::::::::::::::: */
  .title {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    height: 22vh;
    width: 100%;
    font-size: 34px;
    border-bottom: 4px solid rgb(0, 0, 0);
    box-shadow: 0 30px 40px rgba(0, 0, 0, 0.1);
    background: white;
    margin: 0;
    -webkit-backface-visibility: hidden;
  }
  .title-start {
    height: 100vh;
    transition: height 1.2s ease-in-out;
    -webkit-backface-visibility: hidden;
  }
  .title-end {
    transition: height 1.2s ease-in-out;
    -webkit-backface-visibility: hidden;
  }
  .title-gone {
    opacity: 0;
    transition: opacity 1.2s ease;
  }

  /* :::::::::::: SECOND TITLE SECTION :::::::::::::::: */
  .second-title {
    height: 22vh;
    position: relative;
    width: 100%;
    display: flex;
    -webkit-backface-visibility: hidden;
    transition: height 1.2s, opacity 1.2s ease-in-out;
  }
  .sec-title-start {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    transition: height 1.2s, opacity 1.2s ease-in-out;
  }

  .sec-title-end {
    opacity: 1;
    display: flex;
    position: relative;
    border-bottom: 4px solid rgb(0, 0, 0);
    -webkit-backface-visibility: hidden;
    transition: height 1.2s, opacity 1.2s ease-in-out;
  }

  .second-title-bg {
    width: 100%;
    filter: blur(2px) brightness(90%);
    background: center center;
    opacity: 1;
    z-index: -1;
    background-size: cover;
    -webkit-backface-visibility: hidden;
    background-image: url("https://i.imgur.com/yA5TtIC.jpg");
  }

  .second-text-title {
    position: absolute;
    color: white;
    width: 100%;
    font-family: "Homemade Apple", cursive;
    font-size: 36px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
/* PHONE SCREEEEEEENNNNSSSS */
@media all and (max-width: 900px) {
  /* :::::::::::: FIRST TITLE SECTION :::::::::::::::: */
  .title {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    height: 22vh;
    width: 100%;
    font-size: 34px;
    border-bottom: 4px solid rgb(0, 0, 0);
    box-shadow: 0 30px 40px rgba(0, 0, 0, 0.1);
    background: white;
    margin: 0;
    -webkit-backface-visibility: hidden;
  }
  .title-start {
    height: 100vh;
    transition: height 1.2s ease-in-out;
    -webkit-backface-visibility: hidden;
  }
  .title-end {
    transition: height 1.2s ease-in-out;
    -webkit-backface-visibility: hidden;
  }
  .title-gone {
    opacity: 0;
    transition: opacity 1.2s ease;
  }

  /* :::::::::::: SECOND TITLE SECTION :::::::::::::::: */
  .second-title {
    height: 22vh;
    position: relative;
    width: 100%;
    display: flex;
    -webkit-backface-visibility: hidden;
    transition: height 1.2s, opacity 1.2s ease-in-out;
  }
  .sec-title-start {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    transition: height 1.2s, opacity 1.2s ease-in-out;
  }

  .sec-title-end {
    opacity: 1;
    display: flex;
    position: relative;
    border-bottom: 4px solid rgb(0, 0, 0);
    -webkit-backface-visibility: hidden;
    transition: height 1.2s, opacity 1.2s ease-in-out;
  }

  .second-title-bg {
    width: 100%;
    filter: blur(2px) brightness(90%);
    background: center center;
    opacity: 1;
    z-index: -1;
    background-size: cover;
    -webkit-backface-visibility: hidden;
    background-image: url("https://i.imgur.com/yA5TtIC.jpg");
  }

  .second-text-title {
    position: absolute;
    color: white;
    width: 100%;
    font-family: "Homemade Apple", cursive;
    font-size: 27px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
