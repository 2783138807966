@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One|Cinzel|Cousine|Homemade+Apple|Marck+Script|Maven+Pro|Poiret+One|Unica+One);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One|Cinzel|Cousine|Homemade+Apple|Marck+Script|Maven+Pro|Poiret+One|Unica+One);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One|Cinzel|Cousine|Homemade+Apple|Marck+Script|Maven+Pro|Poiret+One|Unica+One);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One|Cinzel|Cousine|Homemade+Apple|Marck+Script|Maven+Pro|Poiret+One|Unica+One);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One|Cinzel|Cousine|Homemade+Apple|Marck+Script|Maven+Pro|Poiret+One|Unica+One);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);
@import url(https://fonts.googleapis.com/css?family=Alfa+Slab+One|Cinzel|Cousine|Homemade+Apple|Marck+Script|Maven+Pro|Poiret+One|Unica+One);
html {
  min-height: 100vh;
  position: relative;
}

body {
  margin: 0;
  padding: 0;
  position: relative;
  background: rgba(177, 133, 133, 0.107);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* DESKTOP SCREENS */
@media all and (min-width: 900px) {
  /* :::::::::::::::::: Final MIDDLE SECTION ::::::::::::::::::: */
  .main-container {
    max-width: 1400px;
    margin: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 58vh;
    /* min-height: 400px; */
    opacity: 1;
    -webkit-transition: all 2s ease-in-out;
    transition: all 2s ease-in-out;
  }
  .main-box-start {
    opacity: 0;
    -webkit-transition: opacity 1.3s ease-in-out;
    transition: opacity 1.3s ease-in-out;
  }
  .main-box-end {
    opacity: 1;
    -webkit-transition: opacity 1.3s, -webkit-transform 1.2s ease-in-out;
    transition: opacity 1.3s, -webkit-transform 1.2s ease-in-out;
    transition: opacity 1.3s, transform 1.2s ease-in-out;
    transition: opacity 1.3s, transform 1.2s ease-in-out, -webkit-transform 1.2s ease-in-out;
  }
  .main-box-hide {
    opacity: 0;
    height: 0px;
    -webkit-transition: opacity 1.2s, height 1.2s, ease-in-out;
    transition: opacity 1.2s, height 1.2s, ease-in-out;
    z-index: -100;
    overflow: hidden;
  }

  .bar-box-start {
    opacity: 0;
    height: 0px;
    display: none;
    -webkit-transition: opacity 0.9s, height 1.2s ease-in-out;
    transition: opacity 0.9s, height 1.2s ease-in-out;
  }

  .bar-box-move {
    opacity: 0;
    height: 0px;
    display: hidden;
    -webkit-transition: opacity 1.2s, height 1.2s ease-in-out;
    transition: opacity 1.2s, height 1.2s ease-in-out;
  }
  .bar-box-end {
    opacity: 1;
    -webkit-transition: opacity 1.2s, height 1.2s ease-in-out;
    transition: opacity 1.2s, height 1.2s ease-in-out;
    height: 100%;
    display: flex;
  }
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* :::::::::::::::::: CENTER BOX OF MIDDLE SECTION ::::::::::::::::::: */
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  .inner-container {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 90%;
    border-radius: 30px;
    -webkit-animation-duration: 0.7s;
            animation-duration: 0.7s;
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }

  .new-bar {
    max-width: 1400px;
    margin: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 58vh;
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }

  .new-bar.enter-done {
    display: flex;
    height: 56.5vh;
    margin: auto;
    padding: 10px 0 0 0;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
  }

  .new-bar.enter-done {
    display: flex;
    height: 100.5vh;
    margin: auto;
    padding: 10px 0 0 0;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
  }

  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    margin: auto; /* or absolute */
    z-index: 1000;
    color: blue;
    width: 40%;
    height: 80px;
    top: 50%;
    left: 30%;
  }
}

/* PHONE SCREENS */
@media all and (max-width: 900px) {
  /* :::::::::::::::::: Final MIDDLE SECTION ::::::::::::::::::: */
  .main-container {
    max-width: 1400px;
    margin: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    /* min-height: 400px; */
    opacity: 1;
    -webkit-transition: all 2s ease-in-out;
    transition: all 2s ease-in-out;
  }
  .main-box-start {
    opacity: 0;
    -webkit-transition: opacity 1.3s ease-in-out;
    transition: opacity 1.3s ease-in-out;
  }
  .main-box-end {
    opacity: 1;
    -webkit-transition: opacity 1.3s, -webkit-transform 1.2s ease-in-out;
    transition: opacity 1.3s, -webkit-transform 1.2s ease-in-out;
    transition: opacity 1.3s, transform 1.2s ease-in-out;
    transition: opacity 1.3s, transform 1.2s ease-in-out, -webkit-transform 1.2s ease-in-out;
  }
  .main-box-hide {
    opacity: 0;
    height: 0px;
    -webkit-transition: opacity 1.2s, height 1.2s ease-in-out;
    transition: opacity 1.2s, height 1.2s ease-in-out;
    z-index: -100;
  }

  .bar-box-start {
    opacity: 0;
    height: 0px;
    display: none;
    -webkit-transition: opacity 0.9s, height 1.2s ease-in-out;
    transition: opacity 0.9s, height 1.2s ease-in-out;
  }

  .bar-box-move {
    opacity: 0;
    height: 0px;
    display: hidden;
    -webkit-transition: opacity 1.2s, height 1.2s ease-in-out;
    transition: opacity 1.2s, height 1.2s ease-in-out;
  }
  .bar-box-end {
    opacity: 1;
    -webkit-transition: opacity 1.2s, height 1.2s ease-in-out;
    transition: opacity 1.2s, height 1.2s ease-in-out;
    height: 100%;
    display: flex;
  }
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* :::::::::::::::::: CENTER BOX OF MIDDLE SECTION ::::::::::::::::::: */
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  .inner-container {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 80%;
    height: 90%;
    border-radius: 30px;
    -webkit-animation-duration: 0.7s;
            animation-duration: 0.7s;
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }

  .new-bar {
    max-width: 1400px;
    margin: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 58vh;
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }

  .new-bar.enter-done {
    display: flex;
    height: 56.5vh;
    margin: auto;
    padding: 10px 0 0 0;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
  }

  .new-bar.enter-done {
    display: flex;
    height: 100.5vh;
    margin: auto;
    padding: 10px 0 0 0;
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
  }

  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    margin: auto; /* or absolute */
    z-index: 1000;
    color: blue;
    width: 40%;
    height: 80px;
    top: 50%;
    left: 30%;
  }
}

/* DESKTOP SCREENS */
@media all and (min-width: 900px) {
  .column-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 40vh;
    -webkit-animation-duration: 1.9s;
            animation-duration: 1.9s;
    z-index: 1000;
  }

  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* :::::::::::::::::::::::::: INNER BOXES :::::::::::::::::::::::::::: */
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  .section {
    color: white;
    font-size: 32px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 80%;
    height: 70%;
    margin: 30px 30px;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-radius: 6px;
    box-shadow: 3px 3px 35px 2px rgba(247, 15, 15, 0.253);
    -webkit-transition: all 1.3s ease;
    transition: all 1.3s ease;
    overflow: hidden;
    border-radius: 10px;
    -webkit-border-radius: 12px;
  }
  .section:hover {
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
    background: rgba(6, 12, 70, 0.455);
    text-shadow: 0px 0px 6px;
    box-shadow: 0.5px 0.23px 50px 6px rgba(0, 81, 255, 0.411);
  }
  .section:active {
    box-shadow: 6px 8px 20px 10px rgba(228, 181, 29, 0.486);
  }
  /* :::::::::::::::: Background Inner Boxes ::::::::::::::::::::: */
  .image-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .resume-img {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    -webkit-filter: blur(2px);
            filter: blur(2px);
    opacity: 1;
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%);
    z-index: -1000;
  }
  .bio-img {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    -webkit-filter: blur(2px);
            filter: blur(2px);
    opacity: 1;
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%);
    z-index: -1000;
  }

  p {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* PHONE SCREENS */
@media all and (max-width: 900px) {
  .column-container {
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60%;
    -webkit-animation-duration: 1.9s;
            animation-duration: 1.9s;
    z-index: 1000;
  }

  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* :::::::::::::::::::::::::: INNER BOXES :::::::::::::::::::::::::::: */
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  .section {
    color: white;
    font-size: 32px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 90%;
    height: 130px;
    margin: 10px 10px;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-radius: 6px;
    box-shadow: 3px 3px 5px 2px rgba(90, 5, 5, 0.253);
    -webkit-transition: all 1.3s ease;
    transition: all 1.3s ease;
    overflow: hidden;
    border-radius: 10px;
    -webkit-border-radius: 12px;
  }
  .section:hover {
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
    background: rgba(6, 12, 70, 0.455);
    text-shadow: 0px 0px 6px;
    box-shadow: 0.5px 0.23px 6px 6px rgba(0, 81, 255, 0.411);
  }
  .section:active {
    box-shadow: 6px 8px 4px 10px rgba(228, 181, 29, 0.486);
  }
  /* :::::::::::::::: Background Inner Boxes ::::::::::::::::::::: */
  .image-box {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    overflow: hidden;
  }
  .resume-img {
    position: relative;
    width: 100%;
    bottom: 0;
    left: 0;
    -webkit-filter: blur(2px);
            filter: blur(2px);
    opacity: 1;
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%);
    z-index: -1000;
  }
  .bio-img {
    position: relative;
    width: 100%;
    bottom: 0;
    left: 0;
    -webkit-filter: blur(2px);
            filter: blur(2px);
    opacity: 1;
    -webkit-transform: translate(0%, 0%);
            transform: translate(0%, 0%);
    z-index: -1000;
  }

  p {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* DESKTOP SCREENS */
@media all and (min-width: 900px) {
  .column-container-move {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 90%;
    height: 10%;
    -webkit-animation-duration: 1.9s;
            animation-duration: 1.9s;
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* :::::::::::::::::::::::::: INNER BOXES :::::::::::::::::::::::::::: */
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

  .section-move {
    font-size: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 80%;
    height: 4vh;
    margin: 5px;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-radius: 6px;
    box-shadow: 3px 3px 35px 2px rgba(247, 15, 15, 0.253);
    -webkit-transition: all 1.3s ease;
    transition: all 1.3s ease;
    -webkit-animation-duration: 2.3s;
            animation-duration: 2.3s;
    overflow: hidden;
    z-index: 1000;
  }
  p {
    margin: 0;
  }
  .section-move:hover {
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
    background: rgba(6, 12, 70, 0.178);
    box-shadow: 0.5px 0.23px 50px 6px rgba(0, 81, 255, 0.411);
    z-index: 1000;
  }
  .section-move:active {
    box-shadow: 6px 8px 20px 10px rgba(228, 181, 29, 0.486);
  }

  .inner-sec {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ole-container {
    margin: auto;
    min-height: 100%;
    /* position: relative; */
    /* display: flex; */
    width: 100%;
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }

  .x-button {
    display: block;
    margin: auto;
    margin-right: 50px;
    margin-top: 30px;
    color: rgb(46, 41, 41);
    font-size: 38px;
    height: 40px;
    width: 40px;
    text-align: right;
  }

  .x-button:hover {
    color: rgb(87, 39, 109);
    cursor: pointer;
  }

  .active {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: 4px solid rgb(69, 51, 228);
    box-shadow: 2px 0 10px #9ecaed;
    /* box-shadow: 0px 5px blue; */
  }
}

/* PHONE SCREENS */
@media all and (max-width: 900px) {
  .column-container-move {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 10%;
    -webkit-animation-duration: 1.9s;
            animation-duration: 1.9s;
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* :::::::::::::::::::::::::: INNER BOXES :::::::::::::::::::::::::::: */
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

  .section-move {
    font-size: 18px;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    height: 6vh;
    margin: 0px;
    cursor: pointer;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    border-radius: 6px;
    box-shadow: 1px 1px 3px 1px rgba(247, 15, 15, 0.253);
    -webkit-transition: all 1.3s ease;
    transition: all 1.3s ease;
    -webkit-animation-duration: 2.3s;
            animation-duration: 2.3s;
    overflow: hidden;
    z-index: 1000;
  }
  p {
    margin: 0;
  }
  .section-move:hover {
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
    background: rgba(6, 12, 70, 0.078);
    box-shadow: 0.5px 0.23px 3px 2px rgba(0, 81, 255, 0.211);
    z-index: 1000;
  }
  .section-move:active {
    box-shadow: 3px 2px 1px 10px rgba(228, 181, 29, 0.286);
  }

  .inner-sec {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ole-container {
    margin: auto;
    min-height: 100%;
    position: relative;
    /* display: flex; */
    width: 100%;
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
  }

  .x-div {
    display: flex;
    width: 99%;
    margin-bottom: 1.5%;
  }
  i {
    margin: 0;
  }

  .x-button {
    display: flex;
    margin: auto;
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: rgb(46, 41, 41);
    font-size: 38px;
    height: 20px;
    width: 40px;
    text-align: right;
  }

  .x-button:hover {
    color: rgb(87, 39, 109);
    cursor: pointer;
  }

  .active {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border-bottom: 4px solid rgb(69, 51, 228);
    box-shadow: 2px 0 10px #9ecaed;
    /* box-shadow: 0px 5px blue; */
  }
}

.card:hover {
  background-color: #20244948;

  box-shadow: 1 3px rgb(48, 21, 21);
}

.card:active {
  background-color: #303449;
  box-shadow: 1 3px rgb(48, 21, 21);
  -webkit-transform: translateY(4px);
          transform: translateY(4px);
  outline: none;
}

/* For Phones & Pads */
@media all and (max-width: 900px) {
  .container-blog {
    height: 40%;
    margin-bottom: 20px;
    width: 90%;
    margin: auto;
  }
  .outer-card-blog {
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 100%;
    margin: auto;
  }

  .boxerson {
    overflow: hidden;
    float: left;
    height: 160px;
    width: 100%;
    margin: auto;
    margin: 1%;
    margin-top: 10px;
    border-radius: 20px;
  }

  .card {
    height: 100%;

    -webkit-user-select: none;

       -moz-user-select: none;

        -ms-user-select: none;

            user-select: none;
    cursor: pointer;

    z-index: 15;
  }

  .container-image {
    height: 100%;
    min-width: 100%;

    position: absolute;
    z-index: -100;
    margin-top: 0%;
    opacity: 0.93;
    -webkit-filter: brightness(78%);
            filter: brightness(78%);
  }
  .h1-card {
    font-size: 23px;
    font-weight: bold;
    margin: auto;
    padding: 0;
    position: relative;
    z-index: 2;
    color: white;
  }

  h2 {
    font-size: calc(10px + 1vmin);

    margin: auto;
    z-index: 5;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .p-card {
    position: absolute;
    width: 100%;
    bottom: 0;
    margin: auto;
    z-index: 2;
    text-align: center;
    color: rgb(245, 216, 216);
  }
}

/* For Desktops */
@media all and (min-width: 900px) {
  .container-blog {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40%;
    margin-bottom: 20px;
    width: 90%;
    margin: auto;
  }
  .outer-card-blog {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    height: 100%;
    width: 100%;
    margin: auto;
  }

  .boxerson {
    overflow: hidden;
    float: left;
    height: 160px;
    width: 46%;
    margin: auto;
    margin: 1%;
    margin-top: 10px;
    border-radius: 20px;
  }

  .card {
    height: 100%;

    -webkit-user-select: none;

       -moz-user-select: none;

        -ms-user-select: none;

            user-select: none;
    cursor: pointer;

    z-index: 15;
  }

  .container-image {
    width: 100%;

    position: absolute;
    z-index: -100;
    margin-top: -10%;
    opacity: 0.93;
    -webkit-filter: brightness(78%);
            filter: brightness(78%);
  }
  .h1-card {
    font-size: 23px;
    font-weight: bold;
    margin: auto;
    padding: 0;
    position: relative;
    z-index: 2;
    color: white;
  }

  h2 {
    font-size: calc(10px + 1vmin);

    margin: auto;
    z-index: 5;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .p-card {
    position: absolute;
    width: 100%;
    bottom: 0;
    margin: auto;
    z-index: 2;
    text-align: center;
    color: rgb(245, 216, 216);
  }
}

/* DESKTOP SCREENS */
@media all and (min-width: 900px) {
  .portfolio-cont {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    width: 96%;
    margin: auto;
    overflow: hidden;
  }

  .project-cont {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 44%;
    height: 210px;
    overflow: hidden;
    margin: 0% 2% 2% 2%;
    border-radius: 7px;
    border-top-right-radius: 1px;
    background: rgba(39, 36, 187, 0.227);
    box-shadow: 4px 4px 3px rgba(0, 0, 0, 0.562);
    -webkit-transition: box-shadow 0.3s ease, -webkit-transform 0.3s;
    transition: box-shadow 0.3s ease, -webkit-transform 0.3s;
    transition: transform 0.3s, box-shadow 0.3s ease;
    transition: transform 0.3s, box-shadow 0.3s ease, -webkit-transform 0.3s;
  }

  .project-cont:hover {
    /* transform: translate(1px, 2px); */
    -webkit-transition: box-shadow 0.3s smooth, -webkit-transform 0.3s;
    transition: box-shadow 0.3s smooth, -webkit-transform 0.3s;
    transition: transform 0.3s, box-shadow 0.3s smooth;
    transition: transform 0.3s, box-shadow 0.3s smooth, -webkit-transform 0.3s;
    box-shadow: 4px 3px 2px rgba(0, 0, 0, 0.562);
    background: rgba(31, 31, 185, 0.307);
  }

  .portfolio-piccont {
    top: 0;
    height: 110px;
    position: absolute;
    font-size: 18px;
    margin: 0;
    width: 100%;
    object-fit: cover;
    margin-top: -30px;
  }

  .under-img {
    top: 80px;
    width: 100%;
    position: absolute;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
  }

  .port-title {
    border-bottom-left-radius: 50% 20%;
    border-bottom-right-radius: 50% 20%;

    font-size: 18px;
    margin: 0;
    width: 100%;
    background: rgba(9, 33, 65, 0.733);
  }

  .mid-card {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    color: rgb(15, 121, 207);
  }

  .in-cardL {
    text-shadow: 1px 2px 3px rgb(255, 255, 255);
    font-size: 14px;
    width: 40%;
  }
  .in-cardR {
    text-shadow: 1px 2px 3px rgb(255, 255, 255);
    font-size: 14px;
    margin: 0;
    width: 40%;
  }
  .info {
    font-weight: normal;
    font-size: 12px;
    color: rgba(34, 32, 32, 0.685);
    margin: 5px 0px 5px 0px;
    line-height: 12px;
  }

  .card-links {
    border: 1px solid black;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    background: rgba(248, 220, 96, 0.397);
    height: 40px;
    width: 38%;
    margin: 0px 18px;
    box-shadow: 2px 2px 1.5px rgba(0, 0, 0, 0.462);
    -webkit-transition: box-shadow 0.3s ease, -webkit-transform 0.3s;
    transition: box-shadow 0.3s ease, -webkit-transform 0.3s;
    transition: transform 0.3s, box-shadow 0.3s ease;
    transition: transform 0.3s, box-shadow 0.3s ease, -webkit-transform 0.3s;
  }

  .card-links:hover {
    box-shadow: 2px 2px 1.5px rgba(0, 0, 0, 0.462);
    background: rgba(41, 41, 168, 0.307);
    cursor: pointer;
  }

  .card-links:active {
    -webkit-transform: translate(1px, 2px);
            transform: translate(1px, 2px);
    -webkit-transition: box-shadow 0.3s ease, -webkit-transform 0.3s;
    transition: box-shadow 0.3s ease, -webkit-transform 0.3s;
    transition: transform 0.3s, box-shadow 0.3s ease;
    transition: transform 0.3s, box-shadow 0.3s ease, -webkit-transform 0.3s;
    box-shadow: 2px 2px 1.5px rgba(0, 0, 0, 0.462);
    background: rgba(31, 31, 185, 0.307);
    cursor: pointer;
  }
}

/* PHONE SCREENS */
@media all and (max-width: 900px) {
  .portfolio-cont {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    flex-direction: column;
    width: 96%;
    margin: auto;
    overflow: hidden;
  }

  .project-cont {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 90%;
    height: 180px;
    overflow: hidden;
    margin: 0% 2% 6% 2%;
    border-radius: 7px;
    border-top-right-radius: 1px;
    background: rgba(39, 36, 187, 0.227);
    box-shadow: 4px 4px 3px rgba(0, 0, 0, 0.562);
    -webkit-transition: box-shadow 0.3s ease, -webkit-transform 0.3s;
    transition: box-shadow 0.3s ease, -webkit-transform 0.3s;
    transition: transform 0.3s, box-shadow 0.3s ease;
    transition: transform 0.3s, box-shadow 0.3s ease, -webkit-transform 0.3s;
  }

  .project-cont:hover {
    /* transform: translate(1px, 2px); */
    -webkit-transition: box-shadow 0.3s smooth, -webkit-transform 0.3s;
    transition: box-shadow 0.3s smooth, -webkit-transform 0.3s;
    transition: transform 0.3s, box-shadow 0.3s smooth;
    transition: transform 0.3s, box-shadow 0.3s smooth, -webkit-transform 0.3s;
    box-shadow: 4px 3px 2px rgba(0, 0, 0, 0.562);
    background: rgba(31, 31, 185, 0.307);
  }

  .portfolio-piccont {
    top: 0;
    height: 80px;
    position: absolute;
    font-size: 18px;
    margin: 0;
    width: 100%;
    object-fit: cover;
    margin-top: -30px;
  }

  .under-img {
    top: 50px;
    width: 100%;
    position: absolute;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
  }

  .port-title {
    border-bottom-left-radius: 50% 20%;
    border-bottom-right-radius: 50% 20%;

    font-size: 18px;
    margin: 0;
    width: 100%;
    background: rgba(9, 33, 65, 0.733);
  }

  .mid-card {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    color: rgb(15, 121, 207);
  }

  .in-cardL {
    text-shadow: 1px 2px 3px rgb(255, 255, 255);
    font-size: 14px;
    width: 48%;
  }
  .in-cardR {
    text-shadow: 1px 2px 3px rgb(255, 255, 255);
    font-size: 14px;
    margin: 0;
    width: 48%;
  }
  .info {
    font-weight: normal;
    font-size: 12px;
    color: rgba(34, 32, 32, 0.685);
    margin: 5px 0px 5px 0px;
    line-height: 12px;
  }

  .card-links {
    border: 1px solid black;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    background: rgba(248, 220, 96, 0.397);
    height: 40px;
    width: 38%;
    margin: 0px 18px;
    box-shadow: 2px 2px 1.5px rgba(0, 0, 0, 0.462);
    -webkit-transition: box-shadow 0.3s ease, -webkit-transform 0.3s;
    transition: box-shadow 0.3s ease, -webkit-transform 0.3s;
    transition: transform 0.3s, box-shadow 0.3s ease;
    transition: transform 0.3s, box-shadow 0.3s ease, -webkit-transform 0.3s;
  }

  .card-links:hover {
    box-shadow: 2px 2px 1.5px rgba(0, 0, 0, 0.462);
    background: rgba(41, 41, 168, 0.307);
    cursor: pointer;
  }

  .card-links:active {
    -webkit-transform: translate(1px, 2px);
            transform: translate(1px, 2px);
    -webkit-transition: box-shadow 0.3s ease, -webkit-transform 0.3s;
    transition: box-shadow 0.3s ease, -webkit-transform 0.3s;
    transition: transform 0.3s, box-shadow 0.3s ease;
    transition: transform 0.3s, box-shadow 0.3s ease, -webkit-transform 0.3s;
    box-shadow: 2px 2px 1.5px rgba(0, 0, 0, 0.462);
    background: rgba(31, 31, 185, 0.307);
    cursor: pointer;
  }
}

.bio-outer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 99%;
  height: 100%;
  max-width: 1000px;
}

.text-box {
  width: 90%;
  margin: auto;
  font-weight: bold;
  text-align: left;
  font-size: 14px;
  background: rgba(98, 98, 155, 0.2);
  padding: 3%;
  margin: 3% 0%;
  border-radius: 5px;
  box-shadow: 2px 2px 7px 1px rgba(0, 0, 0, 0.185);
}

.vid-box {
  height: 400px;
  width: 80%;
  max-width: 800px;
  /* background: rgb(39, 39, 39); */
  /* box-shadow: -4px 3px 4px 1px rgb(0, 0, 0); */
  margin-bottom: 2%;
}

.pic-box {
  overflow: hidden;
  max-width: 70%;
  background: rgb(104, 65, 65);
  box-shadow: -4px 3px 4px 1px rgba(0, 0, 0, 0.247);
  border-radius: 10px;
}
.pic-img {
  max-width: 100%;
}

.too-tall {
  max-height: 400px;
}

/* For DESKTOPS */
@media all and (min-width: 900px) {
  .resume-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: auto;
  }

  .resume-app-cont {
    overflow: hidden;
    -webkit-filter: sharpen(0px);
            filter: sharpen(0px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 40%;
    width: 100%;
    padding: 2%;
    margin: 0% 1.5% 0% 2%;
    border: 1px solid rgb(0, 0, 19);
    border-radius: 5px;
    box-shadow: -10px 10px 7px 3px rgba(0, 0, 0, 0.562);
    -webkit-transition: all 0.8s ease;
    transition: all 0.8s ease;
    background-image: url("https://cdn.pixabay.com/photo/2016/09/08/20/52/milky-way-1655504_1280.jpg");
  }

  .resume-app-cont:hover {
    -webkit-transition: all 0.8s ease;
    transition: all 0.8s ease;
    /* transform: translate(-3px, 3px); */
    box-shadow: -5px 5px 3px 1.5px rgba(0, 0, 0, 0.562);
  }

  .resume-pic {
    border-radius: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    position: relative;
    width: 90%;
  }

  .resume-link-sec {
    display: flex;
    position: relative;
    width: 90%;
    margin: auto;
  }

  .btnres {
    width: 25%;
    padding: 1%;
    color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: rgb(56, 63, 130);
    background: -webkit-gradient(
      linear,
      left bottom, left top,
      from(rgba(56, 63, 130, 1)),
      color-stop(38%, rgba(54, 9, 121, 0.7982901778875613)),
      to(rgba(0, 21, 255, 0.8010912983357406))
    );
    background: -webkit-linear-gradient(
      bottom,
      rgba(56, 63, 130, 1) 0%,
      rgba(54, 9, 121, 0.7982901778875613) 38%,
      rgba(0, 21, 255, 0.8010912983357406) 100%
    );
    background: linear-gradient(
      0deg,
      rgba(56, 63, 130, 1) 0%,
      rgba(54, 9, 121, 0.7982901778875613) 38%,
      rgba(0, 21, 255, 0.8010912983357406) 100%
    );
  }

  .btnres:hover {
    background: -webkit-gradient(
      linear,
      left bottom, left top,
      from(rgba(49, 59, 148, 0.644)),
      color-stop(38%, rgba(70, 17, 150, 0.562)),
      to(rgba(13, 30, 223, 0.623))
    );
    background: -webkit-linear-gradient(
      bottom,
      rgba(49, 59, 148, 0.644) 0%,
      rgba(70, 17, 150, 0.562) 38%,
      rgba(13, 30, 223, 0.623) 100%
    );
    background: linear-gradient(
      0deg,
      rgba(49, 59, 148, 0.644) 0%,
      rgba(70, 17, 150, 0.562) 38%,
      rgba(13, 30, 223, 0.623) 100%
    );
    cursor: pointer;
    -webkit-transition: -webkit-transform 0.5s ease-out;
    transition: -webkit-transform 0.5s ease-out;
    transition: transform 0.5s ease-out;
    transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
  }

  .btnres:active {
    -webkit-transform: translate(-3px, 3px);
            transform: translate(-3px, 3px);

    -webkit-user-select: none;

       -moz-user-select: none;

        -ms-user-select: none;

            user-select: none;
  }

  .resume-pdf-link {
    margin-right: 25%;
  }

  .resume-app-link {
    margin-left: 25%;
  }
}

/* For PHONES */
@media all and (max-width: 900px) {
  .resume-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
  }

  .resume-app-cont {
    overflow: hidden;
    -webkit-filter: sharpen(0px);
            filter: sharpen(0px);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 60%;
    width: 100%;
    padding: 0%;
    margin: 0% 1.5% 0% 2%;
    /* border: 1px solid rgb(0, 0, 19); */
    border-radius: 5px;
    /* box-shadow: -10px 10px 7px 3px rgba(0, 0, 0, 0.562); */
    -webkit-transition: all 0.8s ease;
    transition: all 0.8s ease;
    /* background-image: url("https://cdn.pixabay.com/photo/2016/09/08/20/52/milky-way-1655504_1280.jpg"); */
  }

  .resume-app-cont:hover {
    -webkit-transition: all 0.8s ease;
    transition: all 0.8s ease;
    /* transform: translate(-3px, 3px); */
    /* box-shadow: -5px 5px 3px 1.5px rgba(0, 0, 0, 0.562); */
  }

  .resume-pic {
    border-radius: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    position: relative;
    width: 100%;
  }

  .resume-link-sec {
    display: flex;
    position: relative;
    width: 100%;
    margin: auto;
  }

  .btnres {
    width: 40%;
    padding: 1%;
    color: white;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-radius: 20px;
    margin-bottom: 10px;
    background: rgb(56, 63, 130);
    background: -webkit-gradient(
      linear,
      left bottom, left top,
      from(rgba(56, 63, 130, 1)),
      color-stop(38%, rgba(54, 9, 121, 0.7982901778875613)),
      to(rgba(0, 21, 255, 0.8010912983357406))
    );
    background: -webkit-linear-gradient(
      bottom,
      rgba(56, 63, 130, 1) 0%,
      rgba(54, 9, 121, 0.7982901778875613) 38%,
      rgba(0, 21, 255, 0.8010912983357406) 100%
    );
    background: linear-gradient(
      0deg,
      rgba(56, 63, 130, 1) 0%,
      rgba(54, 9, 121, 0.7982901778875613) 38%,
      rgba(0, 21, 255, 0.8010912983357406) 100%
    );
  }

  .btnres:hover {
    background: -webkit-gradient(
      linear,
      left bottom, left top,
      from(rgba(49, 59, 148, 0.644)),
      color-stop(38%, rgba(70, 17, 150, 0.562)),
      to(rgba(13, 30, 223, 0.623))
    );
    background: -webkit-linear-gradient(
      bottom,
      rgba(49, 59, 148, 0.644) 0%,
      rgba(70, 17, 150, 0.562) 38%,
      rgba(13, 30, 223, 0.623) 100%
    );
    background: linear-gradient(
      0deg,
      rgba(49, 59, 148, 0.644) 0%,
      rgba(70, 17, 150, 0.562) 38%,
      rgba(13, 30, 223, 0.623) 100%
    );
    cursor: pointer;
    -webkit-transition: -webkit-transform 0.5s ease-out;
    transition: -webkit-transform 0.5s ease-out;
    transition: transform 0.5s ease-out;
    transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
  }

  .btnres:active {
    -webkit-transform: translate(-3px, 3px);
            transform: translate(-3px, 3px);

    -webkit-user-select: none;

       -moz-user-select: none;

        -ms-user-select: none;

            user-select: none;
  }

  .resume-pdf-link {
    margin-right: 8%;
    margin-left: 2%;
  }

  .resume-app-link {
    margin-left: 8%;
    margin-right: 2%;
  }
}

@media all and (min-width: 900px) {
  .AppResume {
    width: 90%;
    max-width: 1400px;
    margin: auto;
  }

  .name-bnr {
    width: 100%;
    display: none;
    text-align: center;
    align-content: center;
    margin-top: 40px;
    margin-left: 0.5%;
    font-family: "Lato", sans-serif;
    color: rgb(46, 46, 46);
    font-size: 26px;
    height: 80px;
  }

  .job-bnr {
    font-family: "Lato", sans-serif;
    text-align: center;
    display: none;
    font-size: 20px;
    margin: auto;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    width: 100%;
    height: 70px;
    background-image: -webkit-gradient(
      linear,
      left top, right top,
      from(rgba(47, 101, 163, 0.38)),
      color-stop(rgb(61, 60, 134, 0.38)),
      to(rgb(173, 72, 94, 0.38))
    );
    background-image: -webkit-linear-gradient(
      left,
      rgba(47, 101, 163, 0.38),
      rgb(61, 60, 134, 0.38),
      rgb(173, 72, 94, 0.38)
    );
    background-image: linear-gradient(
      to right,
      rgba(47, 101, 163, 0.38),
      rgb(61, 60, 134, 0.38),
      rgb(173, 72, 94, 0.38)
    );
  }
  .prof-pic {
    margin-top: 10px;
    height: 146px;
    border-radius: 100px;
  }

  .main-containerResume {
    text-align: center;
    display: grid;
    height: 100%;
    max-width: 1000px;
    margin: auto;
    grid-template-columns: 35% 65%;
  }
  .left-sidebar {
    float: left;
    left: 0;
    height: 80%;
  }
  .contact {
    grid-template-columns: 20% 70%;
    display: grid;
    grid-gap: 2%;
    height: 17.85%;
  }

  .cont-item {
    display: flex;
    justify-content: center;
    color: rgb(129, 85, 201);
    background: rgba(233, 230, 230, 0.514);
    font-weight: bold;
    align-items: center;
    font-size: 16px;
    text-decoration: none;
    border: 1px solid rgba(44, 44, 44, 0.159);
    border-radius: 10px;
    font-family: "Baloo Bhai", cursive;
    font-weight: normal;
    -webkit-user-select: text;
       -moz-user-select: text;
        -ms-user-select: text;
            user-select: text;
  }

  .cont-item:hover {
    color: rgba(49, 49, 143, 0.705);
    cursor: pointer;
    background: rgba(184, 180, 180, 0.425);
  }

  .spacer {
    height: 14px;
    width: 99%;
  }
  .spacer2 {
    height: 4.5px;
    width: 99%;
  }

  .left-brdr {
    /* border: 1px solid rgba(0, 0, 0, 0.13);
    border-radius: 10px; */
    font-family: "Lato", sans-serif;
    font-size: 20px;
  }

  .skill-container {
    display: flex;
    padding-bottom: 10px;
    width: 100%;
    /* border: 2px solid rgba(0, 0, 0, 0.142);
    border-radius: 6px; */
    text-align: center;
  }

  .phone-skills {
    width: 90%;
    margin: auto;
  }

  .titles {
    text-align: center;
    display: flex;
    font-size: 28px;
    font-weight: bold;
    height: 50px;
    width: 100%;
    margin-bottom: 6px;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .dev-title {
    font-family: lobster, cursive;
    color: rgba(36, 34, 34, 0.774);
  }

  .divider {
    width: 100%;
    height: 4px;
    background-image: -webkit-gradient(
      linear,
      left top, right top,
      from(rgba(47, 101, 163, 0.38)),
      color-stop(rgb(61, 60, 134, 0.38)),
      to(rgb(173, 72, 94, 0.38))
    );
    background-image: -webkit-linear-gradient(
      left,
      rgba(47, 101, 163, 0.38),
      rgb(61, 60, 134, 0.38),
      rgb(173, 72, 94, 0.38)
    );
    background-image: linear-gradient(
      to right,
      rgba(47, 101, 163, 0.38),
      rgb(61, 60, 134, 0.38),
      rgb(173, 72, 94, 0.38)
    );
    border-radius: 6px;
  }

  .right-block {
    float: right;
    right: 0;
    height: 80%;
  }

  .right-grid {
    float: right;
    width: 95%;
    margin-right: 12px;
  }
  .pdf-btton {
    display: flex;
    width: 55%;
    padding: 2%;
    margin: auto;
    background: rgba(86, 79, 180, 0.315);
    border-radius: 10px;
    text-align: center;
  }

  .popup {
    position: absolute;
    width: 400px;
    height: 400px;
  }
}

/* :::::::::::::::::::::: PHONE VIEW ::::::::::::::::::::::::::: */
@media all and (max-width: 900px) {
  .AppResume {
    width: 90%;
    flex-direction: column;
    margin: auto;
    display: flex;
  }

  .name-bnr {
    width: 100%;
    display: none;
    justify-content: center;
    text-align: center;
    align-content: center;
    margin-top: 20px;
    font-family: "Lato", sans-serif;
    color: rgb(46, 46, 46);
    font-size: 26px;
    height: 60px;
  }

  .job-bnr {
    font-family: "Lato", sans-serif;
    text-align: center;
    display: none;
    font-size: 20px;
    margin: auto;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    width: 100%;
    height: 70px;
    background-image: -webkit-gradient(
      linear,
      left top, right top,
      from(rgba(47, 101, 163, 0.38)),
      color-stop(rgb(61, 60, 134, 0.38)),
      to(rgb(173, 72, 94, 0.38))
    );
    background-image: -webkit-linear-gradient(
      left,
      rgba(47, 101, 163, 0.38),
      rgb(61, 60, 134, 0.38),
      rgb(173, 72, 94, 0.38)
    );
    background-image: linear-gradient(
      to right,
      rgba(47, 101, 163, 0.38),
      rgb(61, 60, 134, 0.38),
      rgb(173, 72, 94, 0.38)
    );
  }
  .prof-pic {
    margin-bottom: 10px;
    height: 146px;
    border-radius: 100px;
  }

  .main-containerResume {
    text-align: center;
    display: flex;
    flex-direction: column;
    height: 90%;
  }
  .left-sidebar {
  }
  .contact {
    grid-template-columns: 15% 70%;
    display: grid;
    grid-gap: 1%;
    height: 22%;
    margin-bottom: 10px;
  }

  .cont-item {
    display: flex;
    justify-content: center;
    color: rgb(132, 78, 219);
    background: rgba(233, 230, 230, 0.514);
    font-weight: bold;
    align-items: center;
    font-size: 16px;
    text-decoration: none;
    border: 1px solid rgba(44, 44, 44, 0.159);
    border-radius: 10px;
    font-family: "Baloo Bhai", cursive;
    font-weight: normal;
    -webkit-user-select: text;
       -moz-user-select: text;
        -ms-user-select: text;
            user-select: text;
  }

  .cont-item:hover {
    color: rgba(49, 49, 143, 0.705);
    cursor: pointer;
    background: rgba(184, 180, 180, 0.425);
  }

  .spacer {
    height: 14px;
    width: 99%;
  }
  .spacer2 {
    height: 4.5px;
    width: 99%;
  }

  .left-brdr {
    /* border: 1px solid rgba(0, 0, 0, 0.13);
    border-radius: 10px; */
    font-family: "Lato", sans-serif;
    font-size: 20px;
  }

  .skill-container {
    display: flex;
    padding-bottom: 10px;
    width: 100%;
    /* border: 2px solid rgba(0, 0, 0, 0.142);
    border-radius: 6px; */
    text-align: center;
  }

  .phone-skills {
    width: 90%;
    margin: auto;
  }

  .titles {
    text-align: center;
    display: flex;
    font-size: 28px;
    font-weight: bold;
    height: 50px;
    width: 100%;
    margin-bottom: 6px;
    align-items: center;
    justify-content: center;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .dev-title {
    font-family: lobster, cursive;
    color: rgba(36, 34, 34, 0.774);
  }

  .divider {
    width: 100%;
    height: 4px;
    background-image: -webkit-gradient(
      linear,
      left top, right top,
      from(rgba(47, 101, 163, 0.38)),
      color-stop(rgb(61, 60, 134, 0.38)),
      to(rgb(173, 72, 94, 0.38))
    );
    background-image: -webkit-linear-gradient(
      left,
      rgba(47, 101, 163, 0.38),
      rgb(61, 60, 134, 0.38),
      rgb(173, 72, 94, 0.38)
    );
    background-image: linear-gradient(
      to right,
      rgba(47, 101, 163, 0.38),
      rgb(61, 60, 134, 0.38),
      rgb(173, 72, 94, 0.38)
    );
    border-radius: 6px;
  }

  .right-block {
    height: 80%;
  }

  .right-grid {
    float: right;
    width: 100%;
  }

  .pdf-btton {
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
    padding: 2%;
    margin: auto;
    background: rgba(86, 79, 180, 0.315);
    border-radius: 10px;
    margin-bottom: 5px;
  }
}

@media print and (min-width: 200px) {
  .AppResume {
    background: white;
    width: 150%;
    -webkit-transform: translateY(-1020px) translateX(-270px) scale(0.7);
            transform: translateY(-1020px) translateX(-270px) scale(0.7);
    margin: auto;
    position: absolute;
    z-index: 1500;
  }
}

.balls {
  font-size: 10px;
  color: rgb(111, 111, 240);
}

.skill-key {
  font-size: 10px;
  margin: 5px;
  margin-left: 0;
  margin-right: 0;
}
.skill-key i {
  font-size: 6px;
}

.skillRow {
  float: left;
  width: 25%;
}

.skillItem {
  content: "";
  display: table;
  clear: both;
  margin: 2px;
  border: 1px dotted rgba(0, 0, 0, 0.115);
  border-radius: 3px;
  width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

@media screen and (max-width: 900px) {
  .skillItem {
    font-size: 10px;
  }
}

.project-containerD {
  display: flex;
  padding: 0;
  margin: 0;
  font-size: 20px;
  width: 100%;
  margin: auto;
}
.Row1 {
  float: left;
  width: 49%;
  margin: auto;
}
.project-row-desk {
  font-weight: bold;
  margin: auto;
  height: 100px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 14px;
  border: 1px solid rgb(148, 129, 129);
  cursor: pointer;
  margin-top: 4px;
  margin-bottom: 0;
  padding: 2px;
  border-radius: 5px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.project-row-desk:hover {
  background: rgba(116, 74, 196, 0.108);
}
.showDetails-desk {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 11px;
  height: 100%;
  font-weight: normal;
}

.project-row-desk:hover {
  background: rgba(92, 123, 165, 0.151);

  box-shadow: 2px 2px 3px rgba(47, 30, 141, 0.404);
}

.outer-education {
  margin: auto;
  font-weight: bold;
  font-size: 16px;
}

.inner-education {
  display: flex;
  float: left;
  width: 28%;
  height: 50%;
  text-align: center;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.inner-right-edu {
  display: flex;
  flex-direction: column;
  float: right;
  width: 70%;
  font-size: 15px;
  height: 50%;
  text-align: center;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.edu-item {
  border: 2px solid rgba(128, 128, 128, 0.329);
  border-radius: 10px;
  display: flex;
  min-height: 80px;
  margin: auto;
  margin-top: 5px;
  padding: 4px;
  font-weight: bold;
  font-size: 15px;
}

.explain {
  font-weight: normal;
  font-size: 13px;
  font-style: italic;
}

.edu-item:hover {
  background: rgba(116, 74, 196, 0.108);
}
@media all and (max-width: 900px) {
  .outer-education {
    font-size: 12px;
  }

  .inner-right-edu {
    font-size: 12px;
  }

  .edu-item {
    font-size: 12px;
  }
  .explain {
    font-size: 11px;
  }
}

.experience-outerD {
  margin-bottom: 20px;
}

.outer-exp {
  margin: auto;
  font-weight: bold;
}

.inner-exp {
  display: flex;
  float: left;
  width: 28%;
  height: 50%;
  text-align: center;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.inner-right-exp {
  display: grid;
  float: right;
  width: 60%;
  text-align: center;
  margin: auto;
  align-items: center;
  justify-content: center;
}
.smaller-text {
  font-size: 12px;
  font-weight: normal;
}

.exp-item {
  border: 2px solid rgba(128, 128, 128, 0.226);

  border-radius: 8px;
  display: flex;

  margin: auto;
  margin-bottom: 3px;
  padding: 4px;
  font-weight: bold;
  font-size: 18px;
}

.exp-item:hover {
  background: rgba(116, 74, 196, 0.108);
}

@media screen and (max-width: 900px) {
  .exp-item {
    font-size: 13px;
  }
}

.outer-personality {
  margin: auto;
  font-weight: bold;
}

.inner-personality {
  display: flex;
  float: left;
  width: 50%;
  height: 50%;
  text-align: center;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.inner-right-personality {
  display: flex;
  float: right;
  width: 70%;
  height: 50%;
  text-align: center;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.personality-item {
  border: 2px solid rgba(128, 128, 128, 0.342);
  border-radius: 10px;
  display: flex;
  min-height: 60px;
  margin: auto;
  margin-top: 5px;
  padding: 4px;
  font-weight: bold;
  font-size: 14px;
  font-size: calc(13px + 3 * ((100% - 750px) / 1000));
}

.personality-item:hover {
  background: rgba(116, 74, 196, 0.108);
}

@media screen and (max-width: 900px) {
  .personality-item {
    font-size: 11px;
  }
}

.program-containerD {
  display: flex;
  padding: 10px;
  border: 2px solid rgba(0, 0, 0, 0.142);
  border-top: none;
  border-radius: 6px;
  text-align: center;
}

.outer-program {
  float: left;
  width: 100%;
}

.prog-item {
  margin: auto;
  font-size: 15px;
  font-weight: bold;
}

.program-containerD:hover {
  background: rgba(116, 74, 196, 0.108);
}

@media screen and (max-width: 900px) {
  .program-containerD {
    display: flex;
    padding: 10px;
    border: 2px solid rgba(0, 0, 0, 0.142);
    border-top: none;
    border-radius: 6px;
    text-align: center;
  }

  .outer-program {
    float: left;
    width: 100%;
  }

  .prog-item {
    margin: auto;
    font-size: 16px;
    font-weight: bold;
  }

  .program-containerD:hover {
    background: rgba(116, 74, 196, 0.108);
  }
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-roller div {
  -webkit-animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-transform-origin: 32px 32px;
          transform-origin: 32px 32px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: rgb(82, 29, 29);
  margin: -3px 0 0 -3px;
}
.lds-roller div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
          animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}
.lds-roller div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
          animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}
.lds-roller div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
          animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}
.lds-roller div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
          animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}
.lds-roller div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
          animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}
.lds-roller div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
          animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}
.lds-roller div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
          animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}
.lds-roller div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
          animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}
@-webkit-keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-roller {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* DESKTOP SCREENS */
@media all and (min-width: 900px) {
  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

  .social-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8vh;
    background-image: -webkit-gradient(
      linear,
      left top, left bottom,
      from(rgba(223, 191, 132, 0.132)),
      color-stop(rgba(77, 255, 187, 0.147)),
      to(rgba(77, 133, 255, 0))
    );
    background-image: -webkit-linear-gradient(
      top,
      rgba(223, 191, 132, 0.132),
      rgba(77, 255, 187, 0.147),
      rgba(77, 133, 255, 0)
    );
    background-image: linear-gradient(
      to bottom,
      rgba(223, 191, 132, 0.132),
      rgba(77, 255, 187, 0.147),
      rgba(77, 133, 255, 0)
    );
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
    margin: auto;
  }

  .social-start {
    opacity: 0;
  }
  .social-end {
    opacity: 1;
  }

  .social-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    color: black;
    margin: 0px 5%;
    cursor: pointer;
    opacity: 0.7;
  }

  a:hover {
    text-decoration: none;
  }

  .social-item:hover {
    opacity: 1;
    color: rgb(52, 29, 156);
  }

  .social-item:active {
    color: rgba(150, 40, 40, 0.747);
  }

  .btn-explain {
    position: absolute;
    opacity: 0;
    color: black;
    font-weight: bold;
    font-size: 0px;
    margin-top: 0px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }

  .social-item:hover + .btn-explain {
    position: absolute;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    opacity: 1;
    font-size: 9px;
    margin-top: 4px;
    text-align: center;
    /* transform: translate(-2px, 0px); */
  }

  .linker {
    text-decoration: none;
    margin: 0px 5%;
  }
  .twit {
    height: 20%;
    z-index: 2;
    opacity: 1;
  }

  .social-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    color: black;
    margin: 0px 5%;
    cursor: pointer;
    opacity: 0.7;
  }
}

/* PHONE SCREENS */
@media all and (max-width: 900px) {
  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

  .social-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 8vh;
    min-height: 80px;
    width: 80%;
    background-image: -webkit-gradient(
      linear,
      left top, left bottom,
      from(rgba(223, 191, 132, 0.132)),
      color-stop(rgba(77, 255, 187, 0.147)),
      to(rgba(77, 133, 255, 0))
    );
    background-image: -webkit-linear-gradient(
      top,
      rgba(223, 191, 132, 0.132),
      rgba(77, 255, 187, 0.147),
      rgba(77, 133, 255, 0)
    );
    background-image: linear-gradient(
      to bottom,
      rgba(223, 191, 132, 0.132),
      rgba(77, 255, 187, 0.147),
      rgba(77, 133, 255, 0)
    );
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
    margin: auto;
  }

  .social-start {
    opacity: 0;
  }
  .social-end {
    opacity: 1;
  }

  .social-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    color: black;
    margin: 0px 5%;
    cursor: pointer;
    opacity: 0.7;
  }

  a:hover {
    text-decoration: none;
  }

  .social-item:hover {
    opacity: 1;
    color: rgb(52, 29, 156);
  }

  .social-item:active {
    color: rgba(150, 40, 40, 0.747);
  }
  .linker {
    text-decoration: none;
    margin: 0px 5%;
  }
  .twit {
    height: 20%;
    z-index: 2;
    opacity: 1;
  }

  .social-img {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    color: black;
    margin: 0px 5%;
    cursor: pointer;
    opacity: 0.7;
  }

  .btn-explain {
    position: absolute;
    opacity: 0;
    color: black;
    font-weight: bold;
    font-size: 0px;
    margin-top: 0px;
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
}

@media all and (min-width: 900px) {
  /* :::::::::::: FIRST TITLE SECTION :::::::::::::::: */
  .title {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    height: 22vh;
    width: 100%;
    font-size: 34px;
    border-bottom: 4px solid rgb(0, 0, 0);
    box-shadow: 0 30px 40px rgba(0, 0, 0, 0.1);
    background: white;
    margin: 0;
    -webkit-backface-visibility: hidden;
  }
  .title-start {
    height: 100vh;
    -webkit-transition: height 1.2s ease-in-out;
    transition: height 1.2s ease-in-out;
    -webkit-backface-visibility: hidden;
  }
  .title-end {
    -webkit-transition: height 1.2s ease-in-out;
    transition: height 1.2s ease-in-out;
    -webkit-backface-visibility: hidden;
  }
  .title-gone {
    opacity: 0;
    -webkit-transition: opacity 1.2s ease;
    transition: opacity 1.2s ease;
  }

  /* :::::::::::: SECOND TITLE SECTION :::::::::::::::: */
  .second-title {
    height: 22vh;
    position: relative;
    width: 100%;
    display: flex;
    -webkit-backface-visibility: hidden;
    -webkit-transition: height 1.2s, opacity 1.2s ease-in-out;
    transition: height 1.2s, opacity 1.2s ease-in-out;
  }
  .sec-title-start {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transition: height 1.2s, opacity 1.2s ease-in-out;
    transition: height 1.2s, opacity 1.2s ease-in-out;
  }

  .sec-title-end {
    opacity: 1;
    display: flex;
    position: relative;
    border-bottom: 4px solid rgb(0, 0, 0);
    -webkit-backface-visibility: hidden;
    -webkit-transition: height 1.2s, opacity 1.2s ease-in-out;
    transition: height 1.2s, opacity 1.2s ease-in-out;
  }

  .second-title-bg {
    width: 100%;
    -webkit-filter: blur(2px) brightness(90%);
            filter: blur(2px) brightness(90%);
    background: center center;
    opacity: 1;
    z-index: -1;
    background-size: cover;
    -webkit-backface-visibility: hidden;
    background-image: url("https://i.imgur.com/yA5TtIC.jpg");
  }

  .second-text-title {
    position: absolute;
    color: white;
    width: 100%;
    font-family: "Homemade Apple", cursive;
    font-size: 36px;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}
/* PHONE SCREEEEEEENNNNSSSS */
@media all and (max-width: 900px) {
  /* :::::::::::: FIRST TITLE SECTION :::::::::::::::: */
  .title {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    height: 22vh;
    width: 100%;
    font-size: 34px;
    border-bottom: 4px solid rgb(0, 0, 0);
    box-shadow: 0 30px 40px rgba(0, 0, 0, 0.1);
    background: white;
    margin: 0;
    -webkit-backface-visibility: hidden;
  }
  .title-start {
    height: 100vh;
    -webkit-transition: height 1.2s ease-in-out;
    transition: height 1.2s ease-in-out;
    -webkit-backface-visibility: hidden;
  }
  .title-end {
    -webkit-transition: height 1.2s ease-in-out;
    transition: height 1.2s ease-in-out;
    -webkit-backface-visibility: hidden;
  }
  .title-gone {
    opacity: 0;
    -webkit-transition: opacity 1.2s ease;
    transition: opacity 1.2s ease;
  }

  /* :::::::::::: SECOND TITLE SECTION :::::::::::::::: */
  .second-title {
    height: 22vh;
    position: relative;
    width: 100%;
    display: flex;
    -webkit-backface-visibility: hidden;
    -webkit-transition: height 1.2s, opacity 1.2s ease-in-out;
    transition: height 1.2s, opacity 1.2s ease-in-out;
  }
  .sec-title-start {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transition: height 1.2s, opacity 1.2s ease-in-out;
    transition: height 1.2s, opacity 1.2s ease-in-out;
  }

  .sec-title-end {
    opacity: 1;
    display: flex;
    position: relative;
    border-bottom: 4px solid rgb(0, 0, 0);
    -webkit-backface-visibility: hidden;
    -webkit-transition: height 1.2s, opacity 1.2s ease-in-out;
    transition: height 1.2s, opacity 1.2s ease-in-out;
  }

  .second-title-bg {
    width: 100%;
    -webkit-filter: blur(2px) brightness(90%);
            filter: blur(2px) brightness(90%);
    background: center center;
    opacity: 1;
    z-index: -1;
    background-size: cover;
    -webkit-backface-visibility: hidden;
    background-image: url("https://i.imgur.com/yA5TtIC.jpg");
  }

  .second-text-title {
    position: absolute;
    color: white;
    width: 100%;
    font-family: "Homemade Apple", cursive;
    font-size: 27px;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}

/* DESKTOP SCREENS */
@media all and (min-width: 900px) {
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* :::::::::::::::::::::::::: FOOTER SECTION :::::::::::::::::::::::::::: */
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  .footer-img {
    position: absolute;
    -webkit-filter: brightness(70%);
            filter: brightness(70%);
    display: flex;
    width: 100%;
    height: 15vh;
    background: center center;
    opacity: 0.9;
    z-index: -1;
    -webkit-filter: hue-rotate(10deg) contrast(240%);
            filter: hue-rotate(10deg) contrast(240%);
    background-size: cover;
  }
  .footer {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 15vh;
    bottom: 0;

    background: rgba(7, 27, 31, 0.76);
    z-index: 100;
    color: white;
    font-size: 26px;
    font-family: "Cousine", monospace;
    margin: auto;
  }

  .foot-text {
    margin: 10px;
  }
  .subscript {
    width: 100%;
    font-size: 14px;
    font-weight: normal;
  }
  .copyrighter {
    margin: 0 10px;
  }
  /* .linker {
    text-decoration: none;
    margin: 0px 5%;
  }
  .twit {
    height: 20%;
    color: blue;
    z-index: 2;
    opacity: 1;
  } */
}

/* PHONE SCREENS */
@media all and (max-width: 900px) {
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* :::::::::::::::::::::::::: FOOTER SECTION :::::::::::::::::::::::::::: */
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  .footer-img {
    position: absolute;
    -webkit-filter: brightness(70%);
            filter: brightness(70%);
    display: flex;
    width: 100%;
    height: 16vh;
    background: center center;
    opacity: 0.9;
    z-index: -1;
    -webkit-filter: hue-rotate(10deg) contrast(240%);
            filter: hue-rotate(10deg) contrast(240%);
    background-size: cover;
  }
  .footer {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 16vh;
    bottom: 0;

    background: rgba(7, 27, 31, 0.76);
    z-index: 100;
    color: white;
    font-size: 16px;
    font-family: "Cousine", monospace;
    margin: auto;
  }

  .foot-text {
    margin: 10px;
  }
  .subscript {
    width: 100%;
    font-size: 12px;
    font-weight: normal;
  }
  .copyrighter {
    margin: 0 10px;
  }
  /* .linker {
    text-decoration: none;
    margin: 0px 5%;
  }
  .twit {
    height: 20%;
    color: blue;
    z-index: 2;
    opacity: 1;
  } */
}

/* DESKTOP SCREENS */
@media all and (min-width: 900px) {
  * {
    box-sizing: border-box;
  }
  .App {
    text-align: center;
    position: relative;
    min-height: 100vh;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    -webkit-transform: translate3d(0, 0, 0);
  }
  .above-footer-cont {
    min-height: 100;
    padding-bottom: 3vh;
  }

  ::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  .body-space {
    position: relative;
    width: 100%;
    min-height: 68vh;
  }
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */
  /* ::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::: */

  /* .backup-fonts {
    font-family: "Maven Pro", sans-serif;
    font-family: "Cinzel", serif;
    font-family: "Alfa Slab One", cursive;
    font-family: "Poiret One", cursive;
    font-family: "Marck Script", cursive;
    font-family: "Unica One", cursive;
    font-family: "Homemade Apple", cursive;
    font-family: "Cousine", monospace;
  } */
}

@media all and (max-width: 900px) {
  * {
    box-sizing: border-box;
  }
  .App {
    text-align: center;
    position: relative;
    min-height: 100vh;
    font-family: "Montserrat", sans-serif;
    font-weight: bold;
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    -webkit-transform: translate3d(0, 0, 0);
  }
  .above-footer-cont {
    min-height: 100;
    padding-bottom: 3vh;
  }

  ::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  .body-space {
    position: relative;
    width: 100%;
    min-height: 68vh;
  }
}

